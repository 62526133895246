<template>
  <div class="wrapper">
    <div class="top-row">
      <div class="cell">
        <v-tabs class="tabs" v-model="activeTab">
          <v-tab
            v-for="tab in tabsList"
            :key="tab.id"
            :to="{ name: tab.routeName }"
            exact
          >
            <span class="tab-name">
              {{ tab.name }}
            </span>
            <v-icon v-if="isLoading">mdi-refresh mdi-spin</v-icon>
            <div
              v-else
              class="tab-amount"
              :class="{ active: amountItems(tab.name) < 1 }"
            >
              {{ amountItems(tab.name) }}
            </div>
          </v-tab>
        </v-tabs>
      </div>
      <div class="cell">
        <!--        <button class="filter-btn">-->
        <!--          <i class="icon-filter"></i> <span>Filter</span>-->
        <!--        </button>-->
        <button
          v-if="isFeedback"
          class="share-btn"
          :disabled="!feedbackIsActive"
          @click="share()"
        >
          Share <i class="icon-share-up"></i>
        </button>
        <button
          v-if="isApproved"
          @click="publishNow()"
          class="share-btn"
          :disabled="!publishRushIsActive"
        >
          Publish Now
        </button>
        <button
          v-if="isApproved"
          class="share-btn"
          @click="isExport = true"
          :disabled="!exportIsActive"
        >
          Export <i class="icon-export"></i>
        </button>
      </div>
    </div>
    <div class="b-row">
      <div class="b-row__item">
        <span class="name">Weekly Posts:</span>
        <span class="data" v-if="getCurrentCustomer.post_per_week">{{
          getCurrentCustomer.post_per_week
        }}</span>
      </div>
      <div class="b-row__item">
        <span class="name">Weekly Stories:</span>
        <span class="data" v-if="getCurrentCustomer.story_per_week">{{
          getCurrentCustomer.story_per_week
        }}</span>
      </div>
    </div>
    <router-view
      :isShare="isShare"
      :isExport="isExport"
      :isExportRush="isExportRush"
      @publishPost="publishPost"
      @isExport="isExport = false"
      @isExportRush="
        isExportRush = false;
        publishRushIsActive = false;
      "
      @feedbackApprovedList="feedbackApprovedList"
      @updateAmountPost="getPostsAmount"
    ></router-view>
    <ModalDialog
      :isShow="shareDialog.isShow"
      :title="shareDialog.title"
      :footerButtons="shareDialog.footerButtons"
      @success="
        isShare = !isShare;
        shareDialog.isShow = false;
      "
      @cancel="shareDialog.isShow = false"
      @close="shareDialog.isShow = false"
    >
      <template v-slot:description
        >You’re about to share all the marked posts with the client. This action
        can’t be undone. Are you sure you want to proceed?</template
      >
    </ModalDialog>
    <Loader v-if="isLoadingExport" class="loader" />
  </div>
</template>

<script>
import {
  CREATE_POST_TABS,
  CREATE_POST_TABS_FOR_META_TESTER,
} from "@/constants/createPost";
import ModalDialog from "@/components/global/ModalDialog";
import Loader from "@/components/global/Loader";
import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
  name: "CreatePostPage",
  data() {
    return {
      isLoading: true,
      isShare: false,
      isExport: false,
      isExportRush: false,
      exportIsActive: false,
      publishRushIsActive: false,
      isLoadingExport: false,
      feedbackIsActive: false,
      shareDialog: {
        isShow: false,
        title: "Sharing with client",
        footerButtons: [
          { name: "Cancel", emitName: "cancel" },
          { name: "Yes", emitName: "success", btnClass: "violet" },
        ],
      },
      posts: [],
      activeTab: `create-post-pre-feedback`,
      tabs: CREATE_POST_TABS,
    };
  },
  components: { ModalDialog, Loader },
  async created() {
    this.isLoading = true;
    if (this.$route.name === "create-post") {
      if (this.getCurrentCustomer.meta_tester) {
        this.$router.push({ name: "create-post-approved" });
      } else this.$router.push({ name: "create-post-pre-feedback" });
    }
    await this.getPostsAmount();
    this.isLoading = false;
  },
  computed: {
    ...mapGetters("clientSelection", ["getCurrentCustomer"]),
    ...mapGetters("createPost", ["getApprovedRushList", "getApprovedList"]),
    isFeedback() {
      return this.$route.name === "create-post-pre-feedback";
    },
    isApproved() {
      return this.$route.name === "create-post-approved";
    },
    tabsList() {
      return this.getCurrentCustomer.meta_tester
        ? CREATE_POST_TABS_FOR_META_TESTER
        : CREATE_POST_TABS;
    },
  },
  watch: {
    getApprovedRushList(val) {
      val.length && val.every((v) => v.isRush === true)
        ? (this.publishRushIsActive = true)
        : (this.publishRushIsActive = false);
    },
  },
  methods: {
    ...mapActions("createPost", [
      "fetchPostsLength",
      "exportPosts",
      "fetchPosts",
    ]),
    ...mapMutations("createPost", ["refreshStateByName"]),
    publishPost(e) {
      e.length > 0
        ? (this.exportIsActive = true)
        : (this.exportIsActive = false);
    },
    feedbackApprovedList(e) {
      e.length > 0
        ? (this.feedbackIsActive = true)
        : (this.feedbackIsActive = false);
    },
    async getPostsAmount() {
      this.posts = await this.fetchPostsLength({
        customerId: this.getCurrentCustomer.id,
      });
      this.isExport = false;
    },
    amountItems(el) {
      return this.posts[el.toLowerCase()] || 0;
    },
    share() {
      this.shareDialog.isShow = !this.shareDialog.isShow;
    },
    publishNow() {
      this.isExportRush = true;
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/vars";
@import "@/assets/styles/mixins";

.wrapper {
  min-height: 100vh;
  background: #f3f2f3;
}
.top-row {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 23px 24px 10px;
  .tabs {
    ::v-deep {
      .v-tabs-slider-wrapper {
        display: none;
      }
      .v-tabs-bar {
        background: transparent;
        height: auto;
        .v-tab {
          width: 167px;
          position: relative;
          margin-right: 16px;
          height: 28px;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          border-bottom: 1px solid rgba(81, 84, 86, 0.4);
          color: rgba(31, 35, 37, 0.5);
          font-weight: 500 !important;
          font-size: 14px !important;
          line-height: 140% !important;
          text-transform: none;
          border-radius: 4px 4px 0px 0px;
          letter-spacing: normal;
          padding: 0 4px;
          &--active {
            border-bottom: 1px solid $color-violet;
            color: $color-violet;
            background: rgba(126, 86, 137, 0.1);
            .tab-amount {
              color: $color-violet;
              background: rgba(126, 86, 137, 0.2) !important;
            }
          }
          .tab-amount {
            background: rgba(78, 87, 120, 0.15);
            border-radius: 6px;
            width: 20px;
            height: 20px;
            font-weight: 500;
            font-size: 12px;
            line-height: 15px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            right: 4px;
            &:before {
              content: "";
              width: 5px;
              height: 5px;
              border: 1px solid $color-white;
              border-radius: 50%;
              background: $color-red;
              right: -1px;
              top: -1px;
              position: absolute;
              display: none;
            }
            &.active {
              &:before {
                display: block;
              }
            }
          }
        }
      }
    }
  }
  .cell {
    display: inline-flex;
    align-items: center;
  }
  .share-btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 36px;
    padding: 0 18px;
    background: $color-violet;
    font-weight: 500 !important;
    font-size: 14px;
    line-height: 16px;
    color: $color-white;
    border-radius: 16px;
    margin-left: 20px;
    transition: all 0.3s ease;
    &:hover {
      background: darken($color-violet, 10);
    }
    i {
      margin-left: 8px;
    }
    &[disabled] {
      background: $color-white;
      color: $color-grey2;
    }
  }
  .filter-btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: $color-violet;
    transition: all 0.3s ease;
    font-weight: 500;
    font-size: 12px;
    span {
      margin-left: 8px;
      text-decoration: underline;
    }
    &:hover {
      color: darken($color-violet, 10);
    }
  }
}
.b-row {
  width: 100%;
  max-width: 549px;
  padding: 0 24px 0 21px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  &__item {
    margin: 0 10px;
    font-weight: 600;
    font-size: 14px;
    line-height: 140%;
    color: #1f2325;
    .data {
      background: #f4e100;
      border-radius: 6px;
      font-weight: 700;
      width: 20px;
      height: 20px;
      margin-left: 7px;
      flex-shrink: 0;
      display: inline-flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.shareModal {
  padding: 36px;
  position: relative;
  .close {
    position: absolute;
    right: 13px;
    top: 13px;
    font-size: 8px;
    color: $color-black;
    opacity: 0.3;
  }
  &__body {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-bottom: 28px;
    .title {
      font-weight: bold;
      font-size: 24px !important;
      line-height: 140%;
      color: $color-black;
      margin-bottom: 16px;
    }
    .desc {
      font-size: 14px;
      line-height: 160%;
      text-align: center;
      color: #515456;
    }
  }
  &__footer {
    .btn-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      .btn {
        margin: 0 10px;
        min-width: 94px;
      }
    }
  }
}
</style>
