export const CREATE_POST_TABS = [
  { id: 1, name: "Pre-Feedback", routeName: `create-post-pre-feedback` },
  { id: 2, name: "Approved", routeName: `create-post-approved` },
  { id: 3, name: "Rejected", routeName: `create-post-rejected` },
  { id: 4, name: "Archive", routeName: `create-post-archive` },
];

export const CREATE_POST_TABS_FOR_META_TESTER = [
  { id: 2, name: "Approved", routeName: `create-post-approved` },
  { id: 3, name: "Rejected", routeName: `create-post-rejected` },
  { id: 4, name: "Archive", routeName: `create-post-archive` },
];
